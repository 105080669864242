"use client";

import clsx from "clsx";
import React from "react";

import { ArrowDropLeftIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropLeftIcon";
import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import { ScrollButton } from "@/components/Scroll/Container";

interface MainTypeInterface {
  onClickLeftButton: () => void;
  onClickRightButton: () => void;
  isShowLeftButton: boolean;
  isShowRightButton: boolean;
  container: React.MutableRefObject<HTMLDivElement | null>;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

const className =
  "p-5 font-medium text-lg uppercase rounded-full bg-white/30 hover:bg-white/50 border border-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none w-10 h-10 m-auto text-center relative z-20";

export const MainScrollType = (props: MainTypeInterface) => {
  return (
    <div className="relative">
      <div className={clsx("flex-start", { hidden: !props.isShowLeftButton })}>
        <div className="absolute left-2 bottom-1/2 translate-y-3/4">
          <ScrollButton onClick={props.onClickLeftButton} className={className}>
            <ArrowDropLeftIcon width="30" height="30" className="relative right-3" fill="#000" />
          </ScrollButton>
        </div>
      </div>
      <div className={clsx("flex-end", { hidden: !props.isShowRightButton })}>
        <div className="absolute right-2 bottom-1/2 translate-y-3/4">
          <ScrollButton onClick={props.onClickRightButton} className={className}>
            <ArrowDropRightIcon width="30" height="30" className="relative right-3" fill="#000" />
          </ScrollButton>
        </div>
      </div>
      <div ref={props.container} className="overflow-x-scroll flex scrollbar-width">
        {props.fallback && props.fallback}
        {props.children}
      </div>
    </div>
  );
};
