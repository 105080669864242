"use client";

import React from "react";

import { ArrowLeftIcon } from "@/components/Icon/Arrow/ArrowLeft";
import { ArrowRightIcon } from "@/components/Icon/Arrow/ArrowRight";
import { ScrollButton } from "@/components/Scroll/Container";

interface CompanyTypeInterface {
  onClickLeftButton: () => void;
  onClickRightButton: () => void;
  isShowLeftButton: boolean;
  isShowRightButton: boolean;
  container: React.MutableRefObject<HTMLDivElement | null>;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

const className =
  "p-5 font-medium text-lg uppercase rounded-full bg-white/30 hover:bg-white/50 border-2 border-kodi-magenta group-focus:ring-4 group-focus:ring-white group-focus:outline-none w-10 h-10 m-auto text-center relative z-20";

export const CompanyScrollType = (props: CompanyTypeInterface) => {
  return (
    <div className="relative">
      <div className="flex gap-10 mb-5">
        <div>
          <ScrollButton onClick={props.onClickLeftButton} className={className}>
            <ArrowLeftIcon width="30" height="30" className="relative right-3 fill-kodi-magenta" />
          </ScrollButton>
        </div>

        <div>
          <ScrollButton onClick={props.onClickRightButton} className={className}>
            <ArrowRightIcon width="30" height="30" className="relative right-3 fill-kodi-magenta" />
          </ScrollButton>
        </div>
      </div>
      <div ref={props.container} className="overflow-x-scroll flex scrollbar-width">
        {props.fallback && props.fallback}
        {props.children}
      </div>
    </div>
  );
};
