"use client";

import { PricedProduct, PricedVariant } from "@medusajs/medusa/dist/types/pricing";
import { computeAmount, useCart } from "medusa-react";
import { useMemo } from "react";

import { useStore } from "@/lib/contexts/store-context";

export type CalculatedVariant = PricedVariant & {
  calculated_price: number;
  calculated_price_type: "sale" | "default";
  original_price: number;
};

export const useProductPrice = ({ product, variantId }: { product: PricedProduct | undefined; variantId?: string }) => {
  const { locale } = useStore();
  const { cart } = useCart();

  const getPercentageDiff = (original: number, calculated: number) => {
    const diff = original - calculated;
    const decrease = (diff / original) * 100;

    return decrease.toFixed();
  };

  const variant = useMemo(
    () => product?.variants?.find((v) => v.id === variantId || v.sku === variantId) as unknown as CalculatedVariant,
    [product, variantId],
  );

  const getCheapestPrice = () => {
    if (!product || !product?.variants?.length || !cart?.region) {
      return null;
    }

    const cheapestVariant = (product.variants as CalculatedVariant[]).reduce((prev, curr) => {
      return prev.calculated_price < curr.calculated_price ? prev : curr;
    });

    return {
      calculated_price: computeAmount({
        amount: cheapestVariant.calculated_price,
        region: cart?.region,
        includeTaxes: false,
      }),
      original_price: computeAmount({
        amount: cheapestVariant.original_price,
        region: cart.region,
        includeTaxes: false,
      }),
      price_type: cheapestVariant.calculated_price_type,
      percentage_diff: getPercentageDiff(cheapestVariant.original_price, cheapestVariant.calculated_price),
    };
  };

  const toLocalString = (price: number | undefined) => {
    return price?.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const calculateExtrapolate = (amount: number) => {
    if (!cart?.region) {
      return null;
    }

    return {
      calculated_price: computeAmount({
        amount: amount,
        region: cart?.region,
        includeTaxes: false,
      }),
    };
  };

  const getVariantPrice = useMemo(() => {
    if (!product || !variant || !cart?.region) {
      return null;
    }

    return {
      calculated_price: computeAmount({
        amount: variant?.calculated_price ?? 0,
        region: cart.region,
        includeTaxes: false,
      }),
      original_price: computeAmount({
        amount: variant?.original_price ?? 0,
        region: cart.region,
        includeTaxes: false,
      }),
      price_type: variant.calculated_price_type,
      percentage_diff: getPercentageDiff(variant.original_price, variant.calculated_price),
    };
  }, [product, variant, cart?.region, locale]);

  return { getCheapestPrice, getVariantPrice, variant, calculateExtrapolate, toLocalString };
};
