"use client";

import Image, { ImageProps } from "next/image";
import { useEffect, useState } from "react";

interface Props extends ImageProps {
  fallbackimage: string;
}

export const PlaceholderImageString = "/img/product_placeholder.png";

export const ProductImage = (props: Props) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [props.src]);

  return (
    <Image
      {...props}
      onError={() => {
        setError(true);
      }}
      alt={props.alt ? props.alt : "Produktbild"}
      src={error ? props.fallbackimage : props.src}
    />
  );
};
