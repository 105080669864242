"use client";

import React, { useEffect, useRef, useState } from "react";

import Button from "@/components/Form/Button/Button";
import { CompanyScrollType } from "@/components/Scroll/Type/CompanyType";
import { MainScrollType } from "@/components/Scroll/Type/MainType";

interface ScrollContainerInterface {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  type?: ContainerTypeEnum;
}

export enum ContainerTypeEnum {
  main = "main",
  company = "company",
}

export const ScrollItem = ({ children, className, onClick }: { children: React.ReactNode; className?: string; onClick?: () => void }) => {
  return <div className={`kodi__scroll__item ${className || ""}`}>{children}</div>;
};

export const ScrollContainer = ({ children, fallback, type = ContainerTypeEnum.main }: ScrollContainerInterface) => {
  const [isShowLeftButton, setShowLeftButton] = useState<boolean>(false);
  const [isShowRightButton, setShowRightButton] = useState<boolean>(false);
  const container = useRef<HTMLDivElement | null>(null);
  const [isFallback, setFallback] = useState<boolean>(!container?.current?.children.length);

  useEffect(() => setFallback(!container?.current?.children.length), [children]);

  const scrollX = (cont: HTMLElement, left: boolean) => {
    let scrollWidthEl = 328;
    const elements = cont.getElementsByClassName("kodi__scroll__item");

    if (elements.length >= 1) {
      scrollWidthEl = elements[elements.length - 1].scrollWidth + 10;
    }

    cont.scrollLeft += left ? scrollWidthEl : -scrollWidthEl;
  };

  const scrollInContainerX = (left: boolean) => {
    container.current && scrollX(container.current, left);
  };

  const eventListener = (initial = false) => {
    const el = container.current;

    if (el) {
      const scrollWidth = !initial ? el.scrollWidth - 10 : el.scrollWidth;

      setShowLeftButton(el.scrollLeft >= 10);
      setShowRightButton(el?.scrollLeft + el?.clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    const el = container.current;

    if (el) {
      setTimeout(() => {
        eventListener(true);
        el.addEventListener("scroll", () => eventListener());
      }, 1000);

      return () => el.removeEventListener("scroll", () => eventListener());
    }
  }, [container.current]);

  return (
    <>
      {type === ContainerTypeEnum.main ? (
        <MainScrollType
          onClickLeftButton={() => scrollInContainerX(false)}
          onClickRightButton={() => scrollInContainerX(true)}
          isShowLeftButton={isShowLeftButton}
          isShowRightButton={isShowRightButton}
          container={container}
          fallback={isFallback ? fallback : null}
        >
          {children}
        </MainScrollType>
      ) : (
        <CompanyScrollType
          onClickLeftButton={() => scrollInContainerX(false)}
          onClickRightButton={() => scrollInContainerX(true)}
          isShowLeftButton={isShowLeftButton}
          isShowRightButton={isShowRightButton}
          container={container}
          fallback={isFallback ? fallback : null}
        >
          {children}
        </CompanyScrollType>
      )}
    </>
  );
};

export const ScrollButton = ({ onClick, children, className }: { onClick: () => void; children: React.ReactNode; className?: string }) => {
  return (
    <Button className={className} rounded onClick={onClick}>
      {children}
    </Button>
  );
};
