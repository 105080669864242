import React from "react";

import { IconInterface } from "@/components/Icon/IconInterface";

export const WishListIcon = ({ className, width, height, onClick }: IconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.39 113.39" onClick={onClick} className={className} width={width} height={height}>
    <path
      d="M56.69,35.83c-12.17-18.26-36.51-7.57-36.51,12.32c0,13.45,12.16,23.04,21.3,30.27
        c9.49,7.51,12.17,9.13,15.21,12.17c3.04-3.04,5.58-4.81,15.21-12.17C81.17,71.33,93.2,61.45,93.2,48
        C93.2,28.26,68.87,17.58,56.69,35.83z"
    />
  </svg>
);
